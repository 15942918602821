import { Jetons } from "./jetons";

export class UserSubscription {
    public jetons: Jetons[];
    public stripeProductName: string;
    
    public static fromJson(json: object): UserSubscription {
      const user = new UserSubscription();
      Object.assign(user, json);
      return user;
    }
}