import { Injectable, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  public visibility: BehaviorSubject<boolean>;
  private trackerSignals: WritableSignal<boolean>[] = [];

  constructor() {
    this.visibility = new BehaviorSubject(false);
  }

  show() {
    this.trackerSignals.push(signal(false));
    this.updateVisibility();
  }

  hide() {
    const signalToSetTrue = this.trackerSignals.find(sig => !sig());
    if (signalToSetTrue) {
      signalToSetTrue.set(true);
    }

    this.updateVisibility();
  }

  getVisibility() {
    return this.visibility.asObservable();
  }

  private updateVisibility() {
    const allComplete = this.trackerSignals.every(sig => sig());
    this.visibility.next(!allComplete);
  }
}
