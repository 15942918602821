import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GoogleMapStats } from '../dto/googlemap/googleMapStats';
import { Backups } from '../dto/googlemap/Backups';
import { typeStepSubscriptionModal } from '../enum/typeStepSubscriptionModal';
import { UserSubscription } from '../dto/user/userSubscription';

@Injectable({ providedIn: 'root' })
export class DataService {

  private filter = undefined;
  private False = null;
  private String = '';

  private appartSize = [];


  private behaviorGoogle = new BehaviorSubject(this.filter);
  private behaviorRadar = new BehaviorSubject(this.False);
  private behaviorRadarDistance = new BehaviorSubject(this.filter);
  private behaviorBuildRadar = new BehaviorSubject(this.False);
  private behaviorFusionDa = new BehaviorSubject(this.False);
  private behaviorBuildPolygon = new BehaviorSubject(this.False);
  private behaviorBuildRectangle = new BehaviorSubject(this.False);
  private behaviorIndice = new BehaviorSubject(this.False);
  private behaviorCancelPolygon = new BehaviorSubject(this.False);
  private behaviorTypeSector = new BehaviorSubject(this.String);
  private behaviorDistanceRadius = new BehaviorSubject(this.filter);
  private behaviorRadarQuantity = new BehaviorSubject(this.filter);
  private behaviorRadarAppart = new BehaviorSubject(this.appartSize);
  private behaviorRadarPeriod = new BehaviorSubject(this.filter);

  private behaviorGoogleStats = new BehaviorSubject(new GoogleMapStats());

  private behaviorTypeRadar = new BehaviorSubject(this.filter);

  private behaviorGoogleMapResearch = new BehaviorSubject(this.filter);
  private behaviorGoogleMapResearchPopUpInfo = new BehaviorSubject(this.filter);

  private behaviorCalculateGraph = new BehaviorSubject(false);

  private behaviorLocalisation = new BehaviorSubject(this.filter);
  private behaviorRadiusActive = new BehaviorSubject(this.filter);
  private behaviorNumberToken = new BehaviorSubject(this.filter);
  private behaviorSubscription = new BehaviorSubject(this.filter);
  private behaviorRenewalDay = new BehaviorSubject(this.filter);
  private behaviorDistanceTypeRadius = new BehaviorSubject(this.filter);
  private behaviorIsLoadRechearch = new BehaviorSubject(false);

  private behaviorEncodePath = new BehaviorSubject(this.filter);
  private behaviorZoomStaticMap = new BehaviorSubject(this.filter);
  private behaviorResetpopupInfo = new BehaviorSubject(this.filter);

  private behaviorStepSubscriptionModal = new BehaviorSubject(
    typeStepSubscriptionModal.hidden
  );

  private behaviorFeatureId = new BehaviorSubject(this.appartSize);

  private behaviorOpenReport = new BehaviorSubject(this.filter);

  private behaviorNewResearch = new BehaviorSubject(this.filter);
  private behaviorNewDocument = new BehaviorSubject(this.filter);

  private behaviorBuildAssistedResearch = new BehaviorSubject(this.filter);
  private behaviorAssistedResearchAppart = new BehaviorSubject(this.filter);
  private behaviorDrawPolygonAssistedResearch = new BehaviorSubject(
    this.filter
  );
  private behaviorArrayPolygon = new BehaviorSubject(this.filter);

  private behaviorStripePaid = new BehaviorSubject(this.filter);
  private behaviorComingFrom = new BehaviorSubject(this.filter);

  private behaviorArrayPath = new BehaviorSubject(this.filter);

  private behaviorOpenAddress = new BehaviorSubject(this.filter);

  private behaviorDashboardAddress = new BehaviorSubject(this.filter);

  private behaviorCollapseMenu = new BehaviorSubject(this.False);

  ToggleCollapseMenu() {
    const currentState = this.behaviorCollapseMenu.getValue();
    this.behaviorCollapseMenu.next(!currentState);
  }

  GetCollapseMenu() {
    return this.behaviorCollapseMenu.asObservable();
  }

  GetCollapseMenuValue() {
    return this.behaviorCollapseMenu.getValue();
  }

  SetResetPopupInfo(value: boolean) {
    this.behaviorResetpopupInfo.next(value);
  }

  SetDashboardAddress(value) {
    this.behaviorDashboardAddress.next(value);
  }

  GetDashboardAddress() {
    return this.behaviorDashboardAddress.getValue();
  }

  SetStepSubscriptionModal(value: number) {
    this.behaviorStepSubscriptionModal.next(value);
  }

  SetGoogle(value) {
    this.behaviorGoogle.next(value);
  }

  GetGoogleValue() {
    return this.behaviorGoogle.getValue();
  }

  SetOpenAddress(value) {
    this.behaviorOpenAddress.next(value);
  }

  GetOpenAddressValue() {
    return this.behaviorOpenAddress.getValue();
  }

  SetArrayPath(value: boolean) {
    this.behaviorArrayPath.next(value);
  }

  GetArrayPath() {
    return this.behaviorArrayPath.asObservable();
  }

  SetStripePaid(value: boolean) {
    this.behaviorStripePaid.next(value);
  }

  GetStripePaid() {
    return this.behaviorStripePaid.asObservable();
  }

  SetArrayPolygon(value: any) {
    this.behaviorArrayPolygon.next(value);
  }

  GetComingFromValue() {
    return this.behaviorComingFrom.value;
  }

  SetComingFrom(value: string) {
    this.behaviorComingFrom.next(value);
  }

  GetArrayPolygon() {
    return this.behaviorArrayPolygon.asObservable();
  }

  SetDrawPolygonAssistedResearch(value: any) {
    this.behaviorDrawPolygonAssistedResearch.next(value);
  }

  GetDrawPolygonAssistedResearch() {
    return this.behaviorDrawPolygonAssistedResearch.asObservable();
  }

  GetDrawPolygonAssistedResearchValue() {
    return this.behaviorDrawPolygonAssistedResearch.value;
  }

  SetNewResearch(value: number) {
    this.behaviorNewResearch.next(value);
  }

  GetNewResearch() {
    return this.behaviorNewResearch.asObservable();
  }

  SetNewDocument(value: number) {
    this.behaviorNewDocument.next(value);
  }

  GetNewDocument() {
    return this.behaviorNewDocument.asObservable();
  }

  SetOpenReport(value: boolean) {
    this.behaviorOpenReport.next(value);
  }

  GetOpenReport() {
    return this.behaviorOpenReport.asObservable();
  }

  SetfeatureIdClicked(value: any[]) {
    this.behaviorFeatureId.next(value);
  }

  GetfeatureIdClicked() {
    return this.behaviorFeatureId.value;
  }

  SetShowInfoOnMobile(value: boolean) {
    const DOM_checkbox = document.getElementById(
      'openPopupInfoMobile'
    ) as HTMLInputElement;
    if (DOM_checkbox) DOM_checkbox.checked = value;
  }

  GetStepSubscriptionModal() {
    return this.behaviorStepSubscriptionModal.asObservable();
  }

  GetStepSubscriptionModalValue() {
    return this.behaviorStepSubscriptionModal.value;
  }

  GetResetPopupInfo() {
    return this.behaviorResetpopupInfo.asObservable();
  }

  SetEncodePath(value: string) {
    this.behaviorEncodePath.next(value);
  }

  SetZoomStaticMap(value: number) {
    this.behaviorZoomStaticMap.next(value);
  }

  SetNumberToken(value: number) {
    this.behaviorNumberToken.next(value);
  }

  SetSubscription(sub: UserSubscription) {
    this.behaviorSubscription.next(sub);
  }

  SetRenewalDay(value: number) {
    this.behaviorRenewalDay.next(value);
  }

  GetRenewalDay() {
    return this.behaviorRenewalDay.asObservable();
  }

  SetIsLoadRechearch(value: boolean) {
    this.behaviorIsLoadRechearch.next(value);
  }

  SetRadarDistance(value: number) {
    this.behaviorRadarDistance.next(value);
  }

  SetRadarAppart(value: any[]) {
    this.behaviorRadarAppart.next(value);
  }

  SetAssistedResearchAppart(value: any[]) {
    this.behaviorAssistedResearchAppart.next(value);
  }

  SetRadarPeriod(value) {
    this.behaviorRadarPeriod.next(value);
  }

  SetRadarQuantity(value: number) {
    this.behaviorRadarQuantity.next(value);
  }

  SetRadar(value: boolean) {
    this.behaviorRadar.next(value);
  }

  SetBuildPolygon(value: boolean) {
    this.behaviorBuildPolygon.next(value);
  }

  SetBuildRectangle(value: boolean) {
    this.behaviorBuildRectangle.next(value);
  }

  SetBuildAssistedResearch(value: boolean) {
    this.behaviorBuildAssistedResearch.next(value);
  }

  SetBuildRadar(value: boolean) {
    this.behaviorBuildRadar.next(value);
  }

  SetFusionDa(value: boolean) {
    this.behaviorFusionDa.next(value);
  }

  SetIndice(value: boolean) {
    this.behaviorIndice.next(value);
  }

  SetDistanceRadius(value: any) {
    this.behaviorDistanceRadius.next(value);
  }

  SetDistanceTypeRadius(value: any) {
    this.behaviorDistanceTypeRadius.next(value);
  }

  SetTypeSector(value: string) {
    this.behaviorTypeSector.next(value);
  }

  SetCancelPolygon(value: boolean) {
    this.behaviorCancelPolygon.next(value);
  }

  SetRadiusActive(value: any) {
    this.behaviorRadiusActive.next(value);
  }

  SetLocalisation(value: any) {
    this.behaviorLocalisation.next(value);
  }

  SetCalculateGraph(value: any) {
    this.behaviorCalculateGraph.next(value);
  }

  SetGoogleMapResearch(research: any) {
    this.behaviorGoogleMapResearch.next(research);
  }

  SetGoogleMapResearchPopupInfo(research: any) {
    this.behaviorGoogleMapResearchPopUpInfo.next(research);
  }

  SetGoogleStats(value: GoogleMapStats) {
    this.behaviorGoogleStats.next(value);
  }

  SetTypeRadar(value: boolean[]) {
    this.behaviorTypeRadar.next(value);
  }

  GetNumberToken() {
    return this.behaviorNumberToken.asObservable();
  }

  GetSubscription() {
    return this.behaviorSubscription.asObservable();
  }

  GetGoogleStats() {
    return this.behaviorGoogleStats.asObservable();
  }

  GetGoogleStatsValue() {
    return this.behaviorGoogleStats.value;
  }

  GetTypeRadar() {
    return this.behaviorTypeRadar.asObservable();
  }

  GetTypeRadarValue(): boolean[] {
    return this.behaviorTypeRadar.value;
  }

  GetGoogleMapResearch(): Backups {
    return this.behaviorGoogleMapResearch.value;
  }

  GetGoogleMapResearchPopUpInfo() {
    return this.behaviorGoogleMapResearchPopUpInfo.asObservable();
  }

  GetCalculateGraph() {
    return this.behaviorCalculateGraph.asObservable();
  }

  GetLocalisation() {
    return this.behaviorLocalisation.asObservable();
  }

  GetTypeSector() {
    return this.behaviorTypeSector.asObservable();
  }

  GetTypeSectorValue() {
    return this.behaviorTypeSector.value;
  }

  GetCancelPolygonValue() {
    return this.behaviorCancelPolygon.value;
  }

  GetDistanceRadius() {
    return this.behaviorDistanceRadius.value;
  }

  GetDistanceTypeRadiusValue() {
    return this.behaviorDistanceTypeRadius.value;
  }

  GetRadiusActive() {
    return this.behaviorRadiusActive.asObservable();
  }

  GetIndice() {
    return this.behaviorIndice.asObservable();
  }

  GetFusionDa() {
    return this.behaviorFusionDa.asObservable();
  }

  GetBuildPolygon() {
    return this.behaviorBuildPolygon.asObservable();
  }

  GetBuildRectangle() {
    return this.behaviorBuildRectangle.asObservable();
  }

  GetBuildRectangleValue() {
    return this.behaviorBuildRectangle.value;
  }

  GetBuildAssistedResearch() {
    return this.behaviorBuildAssistedResearch.asObservable();
  }

  GetBuildAssistedResearchValue() {
    return this.behaviorBuildAssistedResearch.value;
  }

  GetBuildPolygonValue() {
    return this.behaviorBuildPolygon.value;
  }

  GetFusionDaValue() {
    return this.behaviorFusionDa.value;
  }

  GetRadar() {
    return this.behaviorRadar.asObservable();
  }

  GetRadarValue() {
    return this.behaviorRadar.value;
  }

  GetRadarAppartValue() {
    return this.behaviorRadarAppart.value;
  }

  GetAssistedResearchAppartValue() {
    return this.behaviorAssistedResearchAppart.value;
  }

  GetRadarPeriodValue() {
    return this.behaviorRadarPeriod.value;
  }

  GetRadarQuantityValue() {
    return this.behaviorRadarQuantity.value;
  }

  GetRadarDistance() {
    return this.behaviorRadarDistance.asObservable();
  }

  GetIsLoadRechearchValue() {
    return this.behaviorIsLoadRechearch.value;
  }

  GetIsLoadRechearch() {
    return this.behaviorIsLoadRechearch.asObservable();
  }

  GetEncodePath(): string {
    return this.behaviorEncodePath.value;
  }

  GetZoomStaticMap(): number {
    return this.behaviorZoomStaticMap.value;
  }
}
