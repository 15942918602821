import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../dto/user/user';
import { UserProfil } from '../dto/user/userProfil';
import { UserNotification } from '../dto/user/userNotification';
import { UserCreation } from '../dto/user/userCreation';
import { UserSubscription } from '../dto/user/userSubscription';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) {}

  register(user: UserCreation): Observable<object> {
    return this.http
      .post('/api/User/Create', user, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      })
      .pipe(map((data) => data));
  }

  save(user: UserProfil): Observable<any> {
    return this.http
      .post('/api/User/Save', user, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: 'text'
      })
      .pipe((data) => data);
  }

  saveImageProfil(fileToUpload: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('Name', fileToUpload.name);
    formData.append('Image', fileToUpload);
    return this.http
      .post('/api/User/SaveImageProfil', formData, { responseType: 'text' })
      .pipe((data) => data);
  }

  getImageProfil(imageName: string): Observable<object> {
    let params = new HttpParams();
    params = params.append('name', imageName);
    return this.http
      .get('/api/User/GetImageProfil', { params: params, responseType: 'blob' })
      .pipe((val) => val);
  }

  recoverPassword(email: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('email', email);
    return this.http
      .get('/api/User/RecoverPassword', {
        params: params,
        responseType: 'text'
      })
      .pipe((data) => data);
  }

  validateTokenRecover(token: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('token', token);
    return this.http
      .get('/api/User/ValidateTokenRecover', {
        params: params,
        responseType: 'text'
      })
      .pipe((data) => data);
  }

  changePassword(password: string): Observable<object> {
    let params = new HttpParams();
    params = params.append('newpassword', password);
    return this.http
      .get('/api/User/ChangePassword', { params: params })
      .pipe((data) => data);
  }

  resetPassword(password: string, token: string): Observable<object> {
    let params = new HttpParams();
    params = params.append('newpassword', password);
    params = params.append('token', token);
    return this.http
      .get('/api/User/ResetPassword', { params: params })
      .pipe((data) => data);
  }

  checkEmailRegister(email: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('email', email);
    return this.http
      .get('/api/User/CheckEmailRegister', {
        params: params,
        responseType: 'text'
      })
      .pipe((data) => data);
  }

  checkUidAndActivateAcc(email: string, code: string): Observable<any> {
    return this.http
      .post(
        '/api/User/CheckUidAndActivateAcc',
        { email, code },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }
      )
      .pipe((data) => data);
  }

  activateAccount(code: string): Observable<object> {
    return this.http
      .post(
        '/api/User/ActivateAccount',
        { email: '', code },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }
      )
      .pipe((data) => data);
  }

  sendActivateAccUrl(email: string): Observable<string> {
    let params = new HttpParams();
    params = params.append('email', email);
    return this.http
      .get('/api/User/SendActivateAccUrl', {
        params: params,
        responseType: 'text'
      })
      .pipe((data) => data);
  }

  validEmail(): Observable<string> {
    return this.http
      .get('/api/User/ValidEmail', {
        responseType: 'text'
      })
      .pipe((data) => data);
  }

  getNumberOfToken(): Observable<object> {
    return this.http.get('/api/User/GetNumberOfToken').pipe((data) => data);
  }

  GetInfoSubscription(): Observable<UserSubscription> {
    return this.http.get('/api/User/InfoSubscription')
          .pipe(map((jsonItem) => UserSubscription.fromJson(jsonItem as object)));
  }

  getByToken(): Observable<User> {
    return this.http
      .get('/api/User/GetByToken')
      .pipe(map((jsonItem) => User.fromJson(jsonItem as object)));
  }

  setTutorial(): Observable<object> {
    return this.http.get('/api/User/Tutorial').pipe((val) => val);
  }

  getNewResearch(): Observable<object> {
    return this.http.get('/api/User/NewResearch').pipe((val) => val);
  }

  getNewDocument(): Observable<object> {
    return this.http.get('/api/User/NewDocument').pipe((val) => val);
  }

  setNewDocument(value: number): Observable<object> {
    return this.http
      .put('/api/User/SetNewDocument/' + value.toString(), {})
      .pipe((data) => {
        return data;
      });
  }

  setLanguage(lang: string): Observable<object> {
    let params = new HttpParams();
    params = params.append('culture', lang);
    return this.http
      .get('/api/User/SetLanguage', { params: params })
      .pipe((val) => val);
  }

  getProfil(): Observable<UserProfil> {
    return this.http
      .get('/api/User/Profil')
      .pipe(map((jsonItem) => UserProfil.fromJson(jsonItem as object)));
  }

  getIsOwner(): Observable<object> {
    return this.http.get('/api/User/IsOwner').pipe((val) => val);
  }

  getNotification(): Observable<UserNotification> {
    return this.http
      .get('/api/User/Notification')
      .pipe(map((jsonItem) => UserNotification.fromJson(jsonItem as object)));
  }

  setNotification(notification: UserNotification): Observable<object> {
    return this.http
      .put('/api/User/Notification/Update', notification, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      })
      .pipe((data) => data);
  }

  getPopup(id: number): Observable<object> {
    let params = new HttpParams();
    params = params.append('id', id);
    return this.http
      .get('/api/User/GetPopup', { params: params })
      .pipe((data) => data);
  }

  setPopup(id: number): Observable<object> {
    return this.http
      .put('/api/User/SetPopup/' + id, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      })
      .pipe((data) => data);
  }

  refresh(): Observable<object> {
    return this.http.get('/api/User/Refresh').pipe((data) => data);
  }

  delete(): Observable<object> {
    return this.http.delete('/api/User/Delete').pipe((val) => val);
  }

  validToken(quantity: number): Observable<object> {
    return this.http.get('/api/User/ValidToken').pipe((val) => val);
  }
}
